import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GeneralBanner from '../components/generalBanner'
import Work from '../components/work'
import ProjectsInfo from '../components/projects/projectsInfo'
import Certification from '../components/certification'
import Band from "../components/band"

const Projects = () => (
  <Layout>
    <SEO title="Projects" />
    <GeneralBanner
      title="PROJECTS"
    />
    <Work
      background="#fff"
      title="Recent Projects"
      colorTitle="#666"
    />
    <ProjectsInfo />
    <Certification
      background="#fff"
    />
    <Band
      bandBackground="#69B02A"
    />
  </Layout>
)

export default Projects