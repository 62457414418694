import React from 'react'
import PropTypes from "prop-types"
import ButtonLink from './buttonLink'
import { Div, P } from './styled-components'
import EachWork from './eachWork'

const Work = ({
  background,
  title,
  colorTitle,
  spacebar,
  subtitle,
  button
}) => (
  <Div
    padding="60px 10px 30px"
    background_color={background}
    display="flex"
    flexDirection="column"
    align_items="center"
    justify_content="center"
  >
    <P
      margin={spacebar ? "0 0 30px" : "0"}
      fontSize="36px"
      fontWeight="bold"
      color={colorTitle}
      textAlign="center"
    >
      {title}
    </P>
    {(spacebar) &&
      <Div
        width="280px"
        height="2px"
        background_color="#69B02A"
      />
    }
    {(subtitle) &&
      <P
        maxWidth="643px"
        margin="30px 0 0"
        fontSize="20px"
        color="#fff"
        textAlign="center"
      >
        We providing comprehensive services including hospitals, schools, laboratories, airports, restaurants and any type of commercial buildings.
      </P>
    }
    <Div
      margin="60px 0 20px"
      width="860px"
      display="flex"
      justify_content="space-between"
      width960="290px"
      height960="1200px"
      flexDirection960="column"
    >
      <EachWork
        imgIndex={0}
        projectName="NBC Telemundo Center, Miami FL"
      />
      <EachWork
        imgIndex={1}
        projectName="Seminole Casino Hard Rock Live, Hollywood FL"
      />
      <EachWork
        imgIndex={2}
        projectName="Royal Caribbean Terminal A. Miami, FL"
      />
      <EachWork
        imgIndex={3}
        projectName="Arthrex Administration Building, Naples FL"
      />
    </Div>
    <Div
      margin="0 0 60px"
      width="640px"
      display="flex"
      justify_content="space-between"
      width960="290px"
      height960="900px"
      flexDirection960="column"
    >
      <EachWork
        imgIndex={4}
        projectName="400 Sunny Isle Beach, Sunny Isles FL"
      />
      <EachWork
        imgIndex={5}
        projectName="400 Sunny Isles"
      />
      <EachWork
        imgIndex={6}
        projectName="American Express Headquarters, Sunrise FL"
      />
    </Div>
    {(button) &&
      <ButtonLink
        to="/projects"
        background="#666"
      >
        SEE ALL
      </ButtonLink>
    }
  </Div>
)

Work.propTypes = {
  background: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  colorTitle: PropTypes.string.isRequired,
  spacebar: PropTypes.bool,
  subtitle: PropTypes.bool,
  button: PropTypes.bool,
}

export default Work