import React, { useState } from "react"
import PropTypes from "prop-types"
import CloseIcon from "../assets/cerrar.svg";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import { Div, Button, P } from "./styled-components"
import Search from "../assets/search.svg"

const EachWork = ({
  imgIndex,
  projectName
}) => {
  const data = useStaticQuery(graphql`
    query {
      work1: file(relativePath: { eq: "work1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work3: file(relativePath: { eq: "work3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work4: file(relativePath: { eq: "work4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work5: file(relativePath: { eq: "work5.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work6: file(relativePath: { eq: "work6.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work7: file(relativePath: { eq: "work7.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      work8: file(relativePath: { eq: "work8.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const dataArray = Object.values(data)
  const dataImage = dataArray[imgIndex].childImageSharp.fluid

  const [modalIsOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(!modalIsOpen)

  return (
    <>
      <Div
        width="200px"
        height="200px"
        position="relative"
        width960="290px"
        height960="290px"
      >
        <Img
          fluid={dataImage}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
        <Button
          width="100%"
          height="100%"
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          background="rgb(105,176,42,.8)"
          transition="all .5s"
          opacity="0"
          opacityHover="1"
          onClick={openModal}
        >
          <Search />
          <P
            margin="20px 0 0"
            fontSize="20px"
            fontWeight="bold"
            color="#fff"
            textAlign="center"
          >
            Commercial Projects
          </P>
          <P
            margin="0"
            fontSize="18px"
            color="#fff"
            textAlign="center"
          >
            {projectName}
          </P>
        </Button>
      </Div>
      {(modalIsOpen) &&
        <Div
          position="fixed"
          top="0"
          right="0"
          bottom="0"
          left="0"
          zIndex="100"
          background_color="rgb(0,0,0,.9)"
          display="flex"
          align_items="center"
          justify_content="center"
          onClick={openModal}
        >
          <Div
            width="800px"
            width960="100%"
            position="relative"
          >
            <CloseIcon style={{ width: 20, height: 20, zIndex: 10000, position: 'absolute', top: -40, right: 5, cursor: "pointer" }} alt="" />
            <Img
              fluid={dataImage}
              objectFit="contain"
              style={{
                width: "100%",
                height: "100%"
              }}
            />
          </Div>
        </Div>
      }
    </>
  )
}

EachWork.propTypes = {
  imgIndex: PropTypes.number.isRequired,
  projectName: PropTypes.string.isRequired,
}

export default EachWork