import React from 'react';
import PropTypes from 'prop-types';
import { Div, P } from './styled-components';
import ButtonLink from './buttonLink';

const Band = ({ bandBackground }) => (
  <Div
    padding='30px 0 0 0'
    width='100%'
    height='110px'
    background_color={bandBackground}
    display='flex'
    flexDirection='column'
    align_items='center'
    position='relative'
    padding960='24px'
  >
    <P
      margin='0'
      fontSize='28px'
      color='#fff'
      textAlign='center'
      fontSize960='22px'
    >
      ENJOY OUR SERVICES
    </P>
    <Div position='absolute' bottom='-20px' >
      <ButtonLink to='/contact' background='#666'>
        CONTACT US
      </ButtonLink>
    </Div>
  </Div>
);

Band.propTypes = {
  bandBackground: PropTypes.string.isRequired,
};

export default Band;
