import React from 'react'
import PropTypes from "prop-types"
import { StyledLink } from './styled-components'

const ButtonLink = ({
  children,
  background,
  to
}) => (
  <StyledLink
    width="250px"
    height="45px"
    display="flex"
    align_items="center"
    justify_content="center"
    color="#fff"
    textDecoration="none"
    background_color={background}
    to={to}
  >
    {children}
  </StyledLink>
)

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
}

export default ButtonLink