import React from 'react'
import { Div, P } from '../styled-components'
import ProjectBox from './projectBox'

const ProjectsInfo = () => (
  <Div
    padding="50px 20px"
    width="100%"
    display="flex"
    flexDirection="column"
    align_items="center"
    background_color="#23227B"
  >
    <P
      margin="0"
      fontSize="24px"
      fontWeight="bold"
      color="#fff"
      textAlign="center"
    >
      Premier Energy Solutions
    </P>
    <P
      maxWidth="528px"
      margin="0"
      fontSize="20px"
      color="#fff"
      textAlign="center"
      opacity=".6"
    >
      has completed over 1000 projects. Listed below are some of our notable projects with scope details:
    </P>
    <ProjectBox
      title="Commercial Projects"
    >
      <P
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
        opacity=".6"
      >
        American Express Headquarters, Sunrise FL <br/> UTC Center for Intelligence Buildings, Palm Beach FL <br/> NBC Telemundo Center, Miami FL <br/> Seminole Hard Rock Casino, Hollywood FL
      </P>
    </ProjectBox>
    <ProjectBox
      title="Government Projects"
    >
      <P
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
        opacity=".6"
      >
        United States Citizenship and Immigration Services, Fort Myers FL <br/> WT Sampson School, Guantanamo Bay Cuba <br/> VA Hospital, Palm Beach FL
      </P>
    </ProjectBox>
    <ProjectBox
      title="Education Projects"
    >
      <P
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
        opacity=".6"
      >
        Venice Middle School, Venice FL <br/> USF Central Energy Plant, Sarasota FL <br/> John Gray High School, Grand Cayman Island <br/> Fruitville Elementary School, Sarasota FL
      </P>
    </ProjectBox>
    <ProjectBox
      title="Healthcare Projects"
    >
      <P
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
        opacity=".6"
      >
        590 East Leon Medical Center, Hialeah FL <br/> Florida Cancer Specialist, Fort Myers FL <br/> Jackson Memorial Medical Towers, Miami FL <br/> Nicklaus Children’s Hospital, Miami FL
      </P>
    </ProjectBox>
    <ProjectBox
      title="Hospitality Projects"
    >
      <P
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
        opacity=".6"
      >
        Village of Winsor, Boynton Beach FL <br/> The Willough, Naples FL <br/> Residence Inn Marriott, Sunny Isles FL <br/> 400 Sunny Isle Beach, Sunny Isles FL
      </P>
    </ProjectBox>
    <ProjectBox
      title="Retail & Restaurants Projects"
    >
      <P
        margin="0"
        fontSize="18px"
        color="#fff"
        textAlign="center"
        opacity=".6"
      >
        Fogo de Chao, Jacksonville FL <br/> Guitar Center, Delray Beach FL <br/> Home Depot Store # 1863, Bradenton FL <br/> TJ Maxx @ Sunrise Plaza, Orlando FL
      </P>
    </ProjectBox>
  </Div>
)

export default ProjectsInfo