import React from 'react'
import PropTypes from "prop-types"
import { Div, P } from '../styled-components'
import GreenPin from "../../assets/green-pin.svg";

const ProjectBox = ({
  children,
  title
}) => (
  <Div
    margin="50px 0 0 0"
    width="100%"
    display="flex"
    flexDirection="column"
    align_items="center"
  >
    <GreenPin
      alt="green-pin"
      style={{
        width: "70px",
        height: "70px"
      }}
    />
    <P
      margin="20px 0 0 0"
      fontSize="26px"
      fontWeight="bold"
      color="#fff"
      textAlign="center"
    >
      {title}
    </P>
    {children}
  </Div>
)

ProjectBox.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default ProjectBox