import React from 'react'
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Div, H2 } from './styled-components'

const GeneralBanner = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      generalBanner: file(relativePath: { eq: "general-banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 3048) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Div
      width="100%"
      height="300px"
      position="relative"
      display="flex"
      align_items="center"
      justify_content="center"
    >
      <Img
        fluid={data.generalBanner.childImageSharp.fluid}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%"
        }}
      />
      <H2
        fontSize="44px"
        fontWeight="bold"
        textAlign="center"
        color="#fff"
        position="absolute"
      >
        {title}
      </H2>
    </Div>
  )
}

GeneralBanner.propTypes = {
  title: PropTypes.string.isRequired,
}

export default GeneralBanner